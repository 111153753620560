'use strict';
app.controller('indexController', ['$scope', '$state', 'authService', 'broadcastService', '$window', '$cookies', '$rootScope', 'msgService', 'localStorageService', 'controlService', 'signalRService', 'displayService', '$http', '$ocLazyLoad', function ($scope, $state, authService, broadcastService, $window, $cookies, $rootScope, msgService, localStorageService, controlService, signalRService, displayService, $http, $ocLazyLoad)
{
    //invoke httpservice
    broadcastService.extendKendoDatasource();
    
    $rootScope.GetCustomID = function ()
    {
        return broadcastService.s4() + broadcastService.s4() + '-' + broadcastService.s4() + '-' + broadcastService.s4() + '-' + broadcastService.s4() + '-' + broadcastService.s4() + '_' + authService.authentication.clientID;
    };

    $scope.timer = 500;
    $scope.counter = 0;
    $scope.isTrigger = false;

    $scope.langKey = $rootScope.userSetting ? $rootScope.userSetting.langKey : 1;

    $scope.login = function ()
    {
        if ($scope.loginData.userName != "" && $scope.loginData.password != "")
        {
            authService.logOut();
            $scope.message = broadcastService.getMsg(401).MsgBody;//'Authenticating...';
            $scope.isLoggingIn = true;

            signalRService.keepAlive(false);
            //this checking should be no longer work. now clientID is always available regardless of single client or multiple client
            if ($scope.loginData.clientID !== "")
            {
                authService.login($scope.loginData).then(function (response)
                {
                    signalRService.checkAlive().then(function ()
                    {
                        signalRService.startMainSignalR().then(function () { return true; }, function () { return false; }).then(function (startStatus)
                        {
                            signalRService.keepAlive(true);

                            displayService.refresh().then(function () { return true; }, function () { return false; }).then(function (refreshStatus)
                            {
                                broadcastService.broadcast("closeWinLogin", true);
                                $scope.loginData = { userName: "", password: "" };

                                $rootScope.IdleRestart();

                                $rootScope.isShowLogin = false;
                                $rootScope.sendOnce = false;

                                $scope.message = '';
                                $scope.isLoggingIn = false;
                                $rootScope.$emit("CallReloginMethod", {});

                                signalRService.postScreenSize();
                                signalRService.postNetSpeed();
                            });
                        });
                    }, function ()
                    {
                        $scope.message = "Connection to server failed. Please try again later.";
                        $scope.isLoggingIn = false;
                    });
                },
                    function (err)
                    {
                        $scope.message = err.error_description;
                        $scope.isLoggingIn = false;
                    });
            }
            else
            {
                authService.check($scope.loginData).then(function (response)
                {
                    signalRService.checkAlive().then(function ()
                    {
                        signalRService.startMainSignalR().then(function () { return true; }, function () { return false; }).then(function (startStatus)
                        {
                            signalRService.keepAlive(true);

                            displayService.refresh().then(function () { return true; }, function () { return false; }).then(function (refreshStatus)
                            {
                                broadcastService.broadcast("closeWinLogin", true);
                                $scope.loginData = { userName: "", password: "" };

                                $rootScope.IdleRestart();

                                $rootScope.isShowLogin = false;
                                $rootScope.sendOnce = false;

                                $scope.message = '';
                                $scope.isLoggingIn = false;
                                $rootScope.$emit("CallReloginMethod", {});

                                signalRService.postScreenSize();
                                signalRService.postNetSpeed();
                            });
                        });
                    }, function ()
                    {
                        $scope.message = "Connection to server failed. Please try again later.";
                        $scope.isLoggingIn = false;
                    });
                },
                    function (err)
                    {
                        $scope.message = err.error_description;
                        $scope.isLoggingIn = false;
                    });
            }
        }
    };

    $scope.style = [];
    $scope.notificationStyle = [];
    $rootScope.notifications = [];

    $scope.loginData = {
        userName: "",
        password: ""
    };

    $scope.showhideDropDownMenu = function ()
    {
        if ($scope.style.length == 0)
        {
            $scope.style = { 'display': 'block' };
            $scope.notificationStyle = [];
        }
        else
        {
            $scope.style = [];
        }
    };

    $scope.showhideNotification = function ()
    {
        if ($scope.notificationStyle.length == 0)
        {
            $scope.notificationStyle = { 'display': 'block' };
            $scope.style = [];
        }
        else
        {
            $scope.notificationStyle = [];
        }
    };

    $scope.hideDropDownMenu = function ()
    { //Hide Both...
        $scope.style = [];
        $scope.notificationStyle = [];
    }

    $scope.showAll = true;

    $scope.$window = $window;

    $rootScope.$on("SignalRLogout", function ()
    {
        $scope.logOut();
    });

    $rootScope.$on("SignalRReceiveMsg", function (event, data)
    {
        $scope.selectedNotification = data;
    });

    $scope.logOut = function ()
    {
        $window.document.title = $rootScope.appName;
        $rootScope.isShowLogin = false;
        //$scope.style = [];
        $scope.loginData = { userName: "", password: "" };
        $scope.message = '';
        $scope.isLoggingIn = false;

        //wait regardless of success or failure
        signalRService.keepAlive(false);
        signalRService.stopMainSignalR().then(function ()
        {
            signalRService.stopSignalR("workflow");
            signalRService.stopSignalR("procurement");

            $scope.showhideDropDownMenu();
            $state.go('login');

            $rootScope.notifications = [];
            authService.logOut();
        }, function ()
        {
            signalRService.stopSignalR("workflow");
            signalRService.stopSignalR("procurement");

            $scope.showhideDropDownMenu();
            $state.go('login');

            $rootScope.notifications = [];
            authService.logOut();
        });
    }

    $scope.backtoMain = function ()
    {
        if (authService.authentication.isAuth)
        {
            $state.go('dashboard');
        }
        else
        {
            $state.go('home');
        }
    }

    if (!authService.authentication.isAuth)
    {
        $state.go('login');
    }
    
    $scope.addNew = function (data)
    {
        if (typeof data !== "undefined" && data != null)
        {
            let dataClick = null;
            
            let MenuKey = -1;

            if (typeof data.MenuKey !== "undefined")
            {
                MenuKey = data.MenuKey;
            }

            if (typeof data.menuKey !== "undefined")
            {
                MenuKey = data.menuKey;
            }

            if (typeof data.menukey !== "undefined")
            {
                MenuKey = data.menukey;
            }

            if (typeof data.Menukey !== "undefined")
            {
                MenuKey = data.Menukey;
            }

            /* Condition -  Special For Report Bookmark*/
            if (data.type === "report")
            {
                dataClick = {
                    requester: "userclick",
                    requesterID: null,
                    requestedForm: 'reportviewer',
                    action: "create",
                    actionDetail: data.reportFileName,
                    status: "pending",
                    signalCount: 0,
                    name: data.reportName,
                    url: 'master/views/reportviewer.html',
                    jsUrl: 'master/controllers/reportviewerController.js',
                    MenuKey: MenuKey
                };
            }
            else
            {
                dataClick = {
                    requester: "userclick",
                    requesterID: null,
                    requestedForm: data.url.split("/")[data.url.split("/").length - 1].split(".html")[0],
                    action: "none",
                    actionDetail: "nil",
                    status: "pending",
                    signalCount: 0,
                    name: data.name,
                    url: data.url,
                    jsUrl: data.jsUrl,
                    bookmarkData: data,
                    MenuKey: MenuKey
                };
            }

            if (dataClick.requestedForm === 'universal')
            {
                dataClick.action = "mockup";
                dataClick.actionDetail = { moduleName: data.ModuleName, categoryName: data.name, menuKey: MenuKey };
                dataClick.ModuleName = data.ModuleName;
            }

            if (dataClick.requestedForm === 'knowledgebase') {
                dataClick.action = "read";                
                dataClick.actionDetail = "99_" + "index.html";
            }

            if (dataClick.requestedForm === 'userGuide') {
                dataClick.action = "read";
                dataClick.actionDetail = "98_" + "index.html";
            }

            controlService.requestForm(dataClick);
        }
    }
    
    $scope.msgService = msgService;

    //not 100% works....kinda useless, but better than none
    if (typeof window.onunload === "function")
    {
        let JQueryUnload = window.onunload;
        window.onunload = function (e)
        {
            JQueryUnload(e);

            console.log("%cUnload triggered!", 'background:blue;color:#fff');
            signalRService.keepAlive(false);
            signalRService.stopMainSignalR();
            signalRService.stopSignalR("workflow");
            signalRService.stopSignalR("procurement");
        }
    }
    else
    {
        window.onunload = function (e)
        {
            console.log("%cUnload triggered!", 'background:blue;color:#fff');
            signalRService.keepAlive(false);
            signalRService.stopMainSignalR();
            signalRService.stopSignalR("workflow");
            signalRService.stopSignalR("procurement");
        }
    }
    
    window.onbeforeunload = null;

    window.onerror = function (message, source, lineno, colno, error)
    {
        broadcastService.logGeneralJsError(message, source, lineno, colno, error);
    };
    
    $rootScope.getWorkFlowStat = signalRService.getWorkFlowStat;
    $rootScope.getProcStat = signalRService.getProcStat;

    // Make sure we call this only when `document.readyState === "complete"`.
    // It's UI init function has bug which caused it to never fire if readyState was "interactive" when offline.min.js is loaded.
    function loadOfflineJs() {
        // We cannot do `window.Offline.options = {...}` as stated in the documentation because it would be too late
        // to set the configuration in `$ocLazyLoad.load(..).then()`. (`window.Offline` will only available after offline.min.js is loaded.
        //
        // One way to verify if the configuration work for us, is to make sure initiator of Quarto requests ARE NOT offline.min.js.
        // 
        // Initiator should only be offline.min.js when the `options.requests` == true. 
        // `options.interceptRequests` == true would never modify the initiator of requests.
        window.Offline = { options: { requests: false, interceptRequests: true, checks: { xhr: { url: '/connection-test' } } } };
        $ocLazyLoad.load({
            name: "offline",
            files: ['/scripts/offline.min.js', '/content/css/offline-language-english.css', '/content/css/offline-theme-chrome.css']
        }).then(() =>
        {
            Offline.on("up", () =>
            {
                $scope.$apply(() =>
                {
                    $rootScope.$broadcast("refreshDisplayInfo", {});  // reload dashboards
                });
            });
        });
    }

    $(window).on("load", () => {
        if (document.readyState !== "complete") {
            console.error(`document loaded but \`document.readyState\` is not "complete". It is "${document.readyState}" instead.`)
        } else {
            loadOfflineJs();
        }
    })
}]);
